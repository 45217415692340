import { ReactNode } from "react";

import AddIntentModalProvider from "../components/modals/AddIntentModal";
import AddListModalProvider from "../components/modals/AddListModal";
import AddScreenerModalProvider from "../components/modals/AddScreenerModal";
import AddToScreenerModalProvider from "../components/modals/AddToScreenerModal";
import DuplicateScreenerModalProvider from "../components/modals/DuplicateScreenerModal";
import KeywordSuggestionModalProvider from "../components/modals/KeywordSuggestionModal";
import RemoveFromScreenerModalProvider from "../components/modals/RemoveFromScreenerModal";

import MediaQueryProvider from "./MediaQueryProvider";
import PageHeaderProvider from "./PageHeaderProvider";
import ReportIssueProvider from "./ReportIssueProvider";
import SearchProvider from "./SearchProvider";
import ToastProvider from "./ToastProvider";
import UserSettingsProvider from "./UserSettings";
import DeleteScreenerModalProvider from "../components/modals/DeleteScreenerModal";
import EditListModalProvider from "../components/modals/EditListModal";
import EditScreenerModalProvider from "../components/modals/EditScreenerModal";
import EditSearchIterationModalProvider from "../components/modals/EditSearchIterationModal";
import EditSearchModalProvider from "../components/modals/EditSearchModal";

const AllProvider = ({ children }: { children: ReactNode }) => {
  return (
    <MediaQueryProvider>
      <ToastProvider>
        <ReportIssueProvider>
          <UserSettingsProvider>
            <PageHeaderProvider>
              <DuplicateScreenerModalProvider>
                <DeleteScreenerModalProvider>
                  <AddToScreenerModalProvider>
                    <RemoveFromScreenerModalProvider>
                      <AddScreenerModalProvider>
                        <AddIntentModalProvider>
                          <AddListModalProvider>
                            <SearchProvider>
                              <EditScreenerModalProvider>
                                <EditSearchModalProvider>
                                  <EditSearchIterationModalProvider>
                                    <EditListModalProvider>
                                      <KeywordSuggestionModalProvider>
                                        {children}
                                      </KeywordSuggestionModalProvider>
                                    </EditListModalProvider>
                                  </EditSearchIterationModalProvider>
                                </EditSearchModalProvider>
                              </EditScreenerModalProvider>
                            </SearchProvider>
                          </AddListModalProvider>
                        </AddIntentModalProvider>
                      </AddScreenerModalProvider>
                    </RemoveFromScreenerModalProvider>
                  </AddToScreenerModalProvider>
                </DeleteScreenerModalProvider>
              </DuplicateScreenerModalProvider>
            </PageHeaderProvider>
          </UserSettingsProvider>
        </ReportIssueProvider>
      </ToastProvider>
    </MediaQueryProvider>
  );
};

export default AllProvider;
