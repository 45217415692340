import { Box, Typography } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import * as React from "react";

import styles from "./index.module.scss";

const ModalPage = ({
  title,
  titleColor = null,
  centerTitle = false,
  body,
  buttons = null,
  sx,
}: {
  title: string;
  titleColor?: string | null;
  centerTitle?: boolean;
  body: ReactNode;
  buttons?: ReactNode;
  sx?: CSSProperties;
}) => {
  return (
    <Box sx={sx}>
      <Box
        sx={{
          alignContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 900,
            "--title-color": titleColor,
            textAlign: centerTitle ? "center" : "inherit",
          }}
          className={styles.title}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "100%",
        }}
      >
        {body}
      </Box>
      {buttons ? (
        <Box
          sx={{
            paddingTop: "1rem",
            justifyContent: "space-evenly",
            display: "flex",
          }}
        >
          {buttons}
        </Box>
      ) : null}
    </Box>
  );
};

export default ModalPage;
