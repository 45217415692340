import { Typography } from "@mui/material";
import { ReactNode } from "react";
import * as React from "react";

const ModalBodyText = ({ content }: { content: string | ReactNode }) => {
  return (
    <Typography
      sx={{
        paddingTop: "2rem",
      }}
    >
      {content}
    </Typography>
  );
};
export default ModalBodyText;
