import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import GroupIcon from "@mui/icons-material/Group";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  CSSObject,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  Drawer as MuiDrawer,
  Paper,
  Theme,
  Toolbar,
  styled,
} from "@mui/material";
import classNames from "classnames";
import React, { useContext } from "react";
import { useImmer } from "use-immer";

import { PagesWithNavHidden } from "../../constants";
import { PageHeaderContext } from "../../providers/PageHeaderProvider";
import { SearchContext } from "../../providers/SearchProvider";
import {
  SessionContext,
  SessionContextValue,
} from "../../providers/SessionProvider";

import styles from "../index.module.scss";
import AppBarHeader from "./AppBarHeader";
import NavItem from "./NavItem";
import SearchBar from "../SearchBar";

const Nav = () => {
  const session = useContext(SessionContext);
  const search = useContext(SearchContext);
  const pageHeader = useContext(PageHeaderContext);

  const [state, setState] = useImmer<{
    searchInProgress: boolean;
    keywords: string;
  }>({
    searchInProgress: false,
    keywords: "",
  });

  const onLogout = () => {
    pageHeader.setPageHeader("", "");
    session.logout();
    window.location.assign("/login");
  };

  const navItems = [
    {
      primary: "Screeners",
      icon: <LeaderboardIcon />,
      href: "/screeners",
      activeCheck: (path: string) => path.startsWith("/screeners"),
    },
    {
      primary: "Company Lists",
      icon: <CreateNewFolderIcon />,
      href: "/watchlists",
      activeCheck: (path: string) => path.startsWith("/watchlists"),
    },
    {
      primary: "Advanced Search",
      icon: <SearchIcon />,
      href: "/searches",
      activeCheck: (path: string) => path.startsWith("/searches"),
    },
    {
      primary: "Users",
      icon: <GroupIcon />,
      href: "/users",
      activeCheck: (path: string) => path === "/users",
      enabled: (session: SessionContextValue) =>
        session.exists && session.user?.isAdmin,
    },
  ];

  const barContent = session.exists ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        className={styles.hiddenOnMobile}
        style={{
          color: "white",
          whiteSpace: "nowrap",
        }}
      >
        {session.user?.email}
      </div>
      <Box
        className={styles.hiddenOnMobile}
        sx={{
          color: "white",
        }}
      >
        <IconButton
          href="/accounts"
          sx={{
            color: "inherit",
          }}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
      <div className={styles.hiddenOnMobile}>
        <IconButton
          onClick={onLogout}
          sx={{
            color: "white",
          }}
        >
          <LogoutIcon color={"inherit"} />
        </IconButton>
      </div>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "80%",
        margin: "auto",
      }}
    >
      <img
        src={"/fintent-small-notext-transparent.png"}
        alt="Fintent"
        style={{
          maxHeight: "2rem",
        }}
      />
      <div className={classNames(styles.link, styles.hiddenOnMobile)}>
        <a
          href="/login"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          Login
        </a>
      </div>
    </Box>
  );

  const drawerWidth = 105;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const onClear = () => {
    setState((state) => {
      state.keywords = "";
    });
  };

  const endAdornment = state.searchInProgress ? (
    <InputAdornment position="end">
      <CircularProgress
        color="inherit"
        size={16}
      />
    </InputAdornment>
  ) : state.keywords.length > 0 ? (
    <InputAdornment position="end">
      <IconButton onClick={onClear}>
        <CloseOutlinedIcon />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <>
      <Toolbar
        className={styles.nav}
        disableGutters
      >
        <Paper
          className={classNames(styles.header, styles.nav, styles.black, {
            [styles.expanded]: true,
            [styles.hideNav]: PagesWithNavHidden.includes(
              window.location.pathname
            ),
          })}
          sx={{
            width: "100%",
            gap: "1rem",
            borderRadius: 0,
          }}
          elevation={1}
        >
          <SearchBar
            id="global-search"
            value={state.keywords}
            placeholder="Search for company by domain"
            onSearch={(keywords) => {
              setState((state) => {
                state.keywords = keywords;
              });
              search.onSearch({
                keywords: keywords,
              });
            }}
            onClear={onClear}
            inputProps={{
              startAdornment: (
                <SearchIcon
                  sx={{
                    marginRight: "0.5rem",
                  }}
                />
              ),
              endAdornment: endAdornment,
              sx: {
                backgroundColor: "white",
              },
            }}
            style={{
              marginRight: "auto",
              width: "80%",
              display: session.exists ? "inherit" : "none",
            }}
          />
          {barContent}
        </Paper>
      </Toolbar>
      <div>
        {session.exists ? (
          <Drawer
            variant={"permanent"}
            anchor={"left"}
            open={true}
            onClose={() => undefined}
            PaperProps={{
              sx: {
                backgroundColor: "black",
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: "6rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a href={"/"}>
                  <img
                    src={"/fintent-small-notext-transparent.png"}
                    alt="Fintent"
                    style={{
                      maxHeight: "3rem",
                    }}
                  />
                </a>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <List
                  sx={{
                    color: "darkgray",
                    padding: "1rem 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  {navItems.map((item, index) => (
                    <NavItem
                      key={index}
                      primary={item.primary}
                      icon={item.icon}
                      href={item.href}
                      active={item.activeCheck(window.location.pathname)}
                      enabled={item.enabled ? item.enabled(session) : true}
                    />
                  ))}
                </List>
              </Box>
            </Box>
          </Drawer>
        ) : null}
      </div>
      <AppBarHeader />
    </>
  );
};

export default Nav;
